// Button

$brand-action:         #118002 !default;

$btn-action-color:              #fff !default;
$btn-action-bg:                 $brand-action !default;
$btn-action-border:             darken($btn-action-bg, 5%) !default;

.btn-action {
  @include button-variant($btn-action-color, $btn-action-bg, $btn-action-border);
}

.btn {
 
      font-family: $tk-font;
      line-height: 1.5em;

}

.btn.pull-right {
      margin-left: .5em ;
}