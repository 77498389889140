// TypeKit fonts first
@import url("https://use.typekit.net/tit0xuo.css");

$tk-font:       "blambot-pro",sans-serif;


// Framing
@media (min-width: 1200px) {
    .container{
        max-width: 1050px;
    }
}

sidebar {
    background-color: yellow;
    }


.figure-right {
    float: right;
    padding-left: 15px;
    }

.figure .figure-right {
    position: relative;
    border: thin ridge;
    }

.figure-caption {
    font-style: italic;
    }

 .text-title
 {
    font-size: 410%;
    font-weight: 900;
 }


// Inverted navbar
$navbar-inverse-bg:                         #118002 !default;
$navbar-inverse-link-color:                 #FFF !default;
$navbar-inverse-link-hover-color:           #FFFF0A !default;

.navbar
    {
      font-size: 100%;
      font-family: $tk-font;
    }

$brand-primary:         #118002 !default; // #337ab7

// import stylesheets from scss
@import "bootstrap";

// import fca stylesheets
@import "fca-customizations";
@import "fca-buttons";
@import "fca-survey"
