/* used on 2020 survey form */

input[type=text] {
  width: 70%;
  padding: 12px 20px;
  box-sizing: border-box;
  border: 2px solid #ccc;
  border-radius: 4px;
  background-color: #f8f8f8;
  font-size: 14px;
}

textarea {
  width: 70%;
  height: 150px;
  padding: 12px 20px;
  box-sizing: border-box;
  border: 2px solid #ccc;
  border-radius: 4px;
  background-color: #f8f8f8;
  font-size: 14px;
}

form li
{
	margin-top: 20px;
}

li.ranking
{
	background-color: green;
	color: #ffffff;
	width: 50%;
	margin: 3px;
	border: 1px green solid;
	border-radius: 5px;
	padding: 3px 3px 3px 10px;
}

li.ranking:hover { color: #ffc741; }

li.ranking::marker {
	color: white;
	font-weight: 900;
}

input.mc_horiz
{
	margin-left: 15px;
}


/* Tooltip container */
.ranking {
  position: relative;
  display: inline-block;
  border-bottom: 1px dotted black; /* If you want dots under the hoverable text */
}

/* Tooltip text */
.ranking .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: #fff;
  text-align: center;
  padding: 5px;
  border-radius: 6px;
 
  /* Position the tooltip text - see examples below! */
  position: absolute;
  z-index: 1;
}

.ranking .tooltiptext {
  top: -5px;
  right: 105%; 
}

/* Show the tooltip text when you mouse over the tooltip container */
.ranking:hover .tooltiptext {
  visibility: visible;
}

.ranking .tooltiptext::after {
  content: " ";
  position: absolute;
  top: 50%;
  left: 100%; /* To the right of the tooltip */
  margin-top: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent transparent black;
}

.ranking .tooltiptext {
  opacity: 0;
  transition: opacity  0s linear 1s;
}

.ranking:hover .tooltiptext {
  opacity: 1;
}